var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto px-4 sm:px-4 lg:px-8"},[_c('BaseCard',{attrs:{"title":"Penerimaan Barang dari Supplier"}},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex items-center justify-end space-x-2"},[_c('div',{staticClass:"ounded-md relative shadow-sm"},[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.is_received),expression:"filter.is_received"}],staticClass:"focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter, "is_received", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.onReload]}},[_c('option',{domProps:{"value":null}},[_vm._v("Semua")]),_c('option',{domProps:{"value":true}},[_vm._v("Diterima")]),_c('option',{domProps:{"value":false}},[_vm._v("Belum diterima")])])])]),_c('base-search',{on:{"input":_vm.onReloadDebounce},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('div',{staticClass:"mt-2 flex sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"},[_c('Datatable',{attrs:{"id":"daftar_order","total":_vm.getStockMovements.meta.page.total,"perPage":_vm.getStockMovements.meta.page.perPage,"currentPage":_vm.getStockMovements.meta.page.currentPage,"meta":_vm.getStockMovements.meta,"cursor":"","columns":[
              {
                name: 'Nomor Kemasan',
              },
              {
                name: 'Total Produk',
                theadClass: 'text-right px-6 py-4 pr-24',
              },
              {
                name: 'Total Berat',
                theadClass: 'text-right px-6 py-4 pr-24',
              },
              {
                name: 'Status',
                theadClass: 'text-center',
              },
            ]},on:{"pagechanged":_vm.onPageChanged},scopedSlots:_vm._u([{key:"tbody",fn:function(){return [(_vm.isLoading)?_c('div',{staticClass:"flex items-center text-center"},[_c('loading')],1):_vm._e(),(_vm.getStockMovements.data?.length > 0)?_c('tbody',_vm._l((_vm.getStockMovements.data),function(data,dataIdx){return _c('tr',{key:data.id,staticClass:"cursor-pointer bg-white hover:bg-green-100",class:dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50',on:{"click":function($event){$event.preventDefault();return _vm.onViewStockMovement(data)}}},[_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"},[_c('div',{staticClass:"text-sm font-bold text-gray-900"},[_vm._v(" "+_vm._s(_vm.getStockMovementIncluded(data).attributes.code)+" / "+_vm._s(_vm.getStockMovementIncluded(data).attributes.order_code)+" ")]),_c('div',{staticClass:"text-xs font-light text-gray-300"},[_vm._v(" "+_vm._s(_vm.dayjs( _vm.getStockMovementIncluded(data).attributes.updatedAt ).format('ll LT'))+" ")])]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"},[_vm._v(" "+_vm._s(Object.keys( _vm.getStockMovementIncluded(data).attributes .product_summaries ).length)+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 pr-24 text-right text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.countStockMovementWeight(_vm.getStockMovementIncluded(data))))+" ")]),_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"},[(
                        _vm.getStockMovementIncluded(data).attributes.is_received
                      )?_c('span',{staticClass:"inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"},[_vm._v(" Diterima ")]):_c('span',{staticClass:"inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"},[_vm._v(" Belum diterima ")])])])}),0):_c('tbody',[_c('tr',{staticClass:"bg-white"},[_c('td',{staticClass:"whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900",attrs:{"colspan":"8"}},[_vm._v(" Data tidak ditemukan ")])])])]},proxy:true}])})],1)]),_c('stock-movement-modal',{attrs:{"visible":_vm.visibleDetail,"description":"Data penerimaan barang dari Supplier","with-dispute":false,"with-detail-batch":"","force-display-first-packet":""},on:{"close":function($event){_vm.visibleDetail = false},"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"origin-office",fn:function({ originOffice }){return [_c('div',{staticClass:"sm:col-span-2"},[_c('dt',{staticClass:"text-sm font-bold text-gray-700"},[_vm._v("Nomor Supplier")]),_c('dd',{staticClass:"mt-1 text-sm text-gray-900"},[_vm._v(" "+_vm._s(originOffice.attributes.code)+" ")])])]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }